import { render, staticRenderFns } from "./SportsDefaultFooter.vue?vue&type=template&id=0e87497b&scoped=true"
import script from "./SportsDefaultFooter.vue?vue&type=script&lang=ts"
export * from "./SportsDefaultFooter.vue?vue&type=script&lang=ts"
import style0 from "./SportsDefaultFooter.vue?vue&type=style&index=0&id=0e87497b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e87497b",
  null
  
)

export default component.exports